body {
  margin: 0;
  font-family: 'PPMori', sans-serif;
  color: rgba(255, 255, 255, 1);
  line-height: normal;
  font-size: 24px;
  font-weight: 400;
;
}

@font-face {
  font-family: 'PPMori';
  src: url('../public/fonts/PPMori-Regular.otf') format("opentype");
 }